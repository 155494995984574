import { useCallback, useEffect, useState } from "react";
import "./IframeModal.scss";
import desktopIcon from "../../assets/Desktop-icon.png";
import mobileIcon from "../../assets/Mobile-Icon.png";
import mobileIconPortrait from "../../assets/Mobile-Icon-portrait.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useLauncherStore } from "../../store/launcher";

type Props = {
  show: boolean,
  onClose: () => void,
  gameURL: string,
  iFrameReload: boolean,
  iFrameCompatibility: boolean
}

const IframeModal = ({
  show,
  onClose,
  gameURL,
  iFrameReload,
  iFrameCompatibility,
}:Props) => {
  const { mode, setMode } = useLauncherStore((state) => state);
  const [URL, setURL] = useState(gameURL);

  const onCloseClicked = () => {
    if (onClose) onClose();
  };

  const closeModalOnEscKeyDown = useCallback((e: KeyboardEvent) => {
    if (show && e.code === "Escape" && onClose) {
      onClose();
    }
  },[show, onClose]
  );

  const handleResize = useCallback(() => {
    const screenOrientation = window.screen.orientation.type.startsWith("portrait")
      ? "portrait"
      : "landscape";

    if (
      screenOrientation === "landscape" &&
      window.screen.width > 1024 &&
      window.window.innerWidth <= 1024 &&
      mode !== "desktop"
    ) {
      setMode("desktop");
    }

    if (!iFrameCompatibility) {
      if (screenOrientation === "landscape" && window.screen.width <= 1024) {
        setMode("mobileLandscape");
      } else if (screenOrientation === "landscape" && window.screen.width > 1024) {
        setMode("desktop");
      } else if (screenOrientation === "portrait") {
        setMode("mobilePortrait");
      }
    }
  },[mode, setMode, iFrameCompatibility]);

  useEffect(() => {
    let updateURL;
    mode !== "desktop"
      ? (updateURL = gameURL.split("&device=")[0] + "&device=mobile")
      : (updateURL = gameURL.split("&device=")[0] + "&device=desktop");
    setURL(updateURL);
  }, [mode, gameURL]);

  useEffect(() => {
    const screenOrientation = window.screen.orientation.type.startsWith("portrait")
      ? "portrait"
      : "landscape";

    if (screenOrientation === "landscape" && window.screen.width <= 1024) {
      setMode("mobileLandscape");
    } else if (screenOrientation === "landscape" && window.screen.width > 1024) {
      setMode("desktop");
    } else if (screenOrientation === "portrait") {
      setMode("mobilePortrait");
    }

    window.addEventListener("resize", handleResize);
    document.addEventListener("keydown", closeModalOnEscKeyDown);
    return () => {
      document.removeEventListener("keydown", closeModalOnEscKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  if (show) {
    return (
      <div className="modal-iframe-backdrop">
        {iFrameCompatibility && (
          <div className="modal-iframe-content">
            <div className="modal-iframe-header">
              <div></div>
              <FontAwesomeIcon
                icon={faWindowClose as IconProp}
                onClick={onCloseClicked}
                className="modal-close-icon"
              />
            </div>
            <div className="modal-iframe-body">
              {mode === "desktop" && (
                <iframe
                  title="Game Desktop mode"
                  src={URL}
                  className="iframeDesktop"
                ></iframe>
              )}
              {!iFrameReload &&
                (mode === "mobilePortrait" || mode === "mobileLandscape") && (
                  <iframe
                    title="Game Mobile mode"
                    src={URL}
                    className={`${
                      mode === "mobileLandscape"
                        ? "iframeMobileLandscape"
                        : "iframeMobilePortrait"
                    }`}
                  ></iframe>
                )}
              {iFrameReload && mode === "mobilePortrait" && (
                <iframe
                  title="Game Portrait mode"
                  src={URL}
                  className={`${"iframeMobilePortrait"}`}
                ></iframe>
              )}
              {iFrameReload && mode === "mobileLandscape" && (
                <iframe
                  title="Game Landscape mode"
                  src={URL}
                  className={`${"iframeMobileLandscape"}`}
                ></iframe>
              )}
            </div>
            <div className="modal-iframe-footer">
              <img
                src={desktopIcon}
                alt="Desktop Icon"
                onClick={() => {
                  setMode("desktop");
                }}
                className={`${
                  mode === "desktop"
                    ? "modal-item-selected"
                    : "modal-iframe-desktop-icon"
                }`}
              />
              <img
                src={mobileIconPortrait}
                alt="Mobile Portrait Icon"
                onClick={() => {
                  setMode("mobilePortrait");
                }}
                className={`${
                  mode === "mobilePortrait"
                    ? "modal-item-selected"
                    : "modal-iframe-portrait-icon"
                }`}
              />
              <img
                src={mobileIcon}
                alt="Mobile Icon"
                onClick={() => {
                  setMode("mobileLandscape");
                }}
                className={`${
                  mode === "mobileLandscape"
                    ? "modal-item-selected"
                    : "modal-iframe-mobile-icon"
                }`}
              />
            </div>
          </div>
        )}
        {!iFrameCompatibility && mode === "mobilePortrait" && (
          <iframe
            title="Game Full Screen Portrait mode"
            src={URL}
            className={`${"iframeFullScreen"}`}
            allowFullScreen
          ></iframe>
        )}
        {!iFrameCompatibility && mode === "mobileLandscape" && (
          <iframe
            title="Game Full Screen Landscape mode"
            src={URL}
            className={`${"iframeFullScreen"}`}
            allowFullScreen
          ></iframe>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default IframeModal;
