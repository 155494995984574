import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import { blue } from "@mui/material/colors";
import { Params } from "../../../interfaces/interfaces";

type Props = {
  params: Params;
  handleChange: <P extends keyof Params>(prop: P, value: Params[P]) => void;
};

export function DeviceRadio({ params, handleChange }: Props) {
  return (
    <div>
      <FormLabel className="label-padding">Device</FormLabel>
      <Radio
        name="device"
        value="desktop"
        id="desktop"
        checked={params.device === "desktop"}
        onChange={(e) => {
          handleChange("device", e.target.value);
        }}
        sx={{
          color: blue[800],
          "&.Mui-checked": {
            color: blue[600],
          },
        }}
      />
      <span>desktop</span>
      <Radio
        name="device"
        value="mobile"
        id="mobile"
        checked={params.device === "mobile"}
        onChange={(e) => {
          handleChange("device", e.target.value);
        }}
        sx={{
          color: blue[800],
          "&.Mui-checked": {
            color: blue[600],
          },
        }}
      />
      <span>mobile</span>
    </div>
  );
}
