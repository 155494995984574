import "./Registration.scss";
import logo from "../assets/zitro-digital.png";
import Particles from "react-tsparticles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faIdCard,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import particlesConfig from "../config/particles.json";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useRegistration } from "../hooks/useRegistration";
import { useGameParamsStore } from "../store/gameParams";
import { useState } from "react";

type Props = {
  registrationApiUrl: string;
  promotedGame: string;
  gameUrl: string;
};

export default function Registration({
  registrationApiUrl,
  promotedGame,
  gameUrl,
}: Props) {
  const registration = useRegistration(registrationApiUrl);
  const { params } = useGameParamsStore((state) => state);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const nick = (e.currentTarget.elements[0] as HTMLInputElement).value;
    let email = (e.currentTarget.elements[1] as HTMLInputElement).value;
    const name = (e.currentTarget.elements[2] as HTMLInputElement).value;
    const company = (e.currentTarget.elements[3] as HTMLInputElement).value;

    if (nick.length < 4 || nick.length > 25) {
      setErrorMessage("Username must have between 4-25 chars");
      return;
    }

    const nickRegex = new RegExp(/^[a-z0-9_.-]{4,25}$/);
    if (!nickRegex.test(nick)) {
      setErrorMessage(
        "Username only can contain lower case chars, digits and the special chars - . _"
      );
      return;
    }

    email = email.toLowerCase();

    if (email.length < 6 || email.length > 45) {
      setErrorMessage("Email must have between 6-45 chars");
      return;
    }

    const emailRegex = new RegExp(
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
    );
    if (!emailRegex.test(email)) {
      setErrorMessage("Email format is incorrect");
      return;
    }

    if (name.length > 45) {
      setErrorMessage("Real name can not be longer than 45 chars");
      return;
    }

    const fullNameRegex = new RegExp(/^([^0-9]*)$/);
    if (name.length > 0 && !fullNameRegex.test(name)) {
      setErrorMessage("Name can not contain numbers");
      return;
    }

    if (company.length > 20) {
      setErrorMessage("Company can not be longer than 20 chars");
      return;
    }

    try {
      const token = await registration.mutateAsync({
        userData: { nick, email, name, company },
      });

      const device = params.isMobile ? "mobile" : "desktop";
      const url = `${gameUrl}?game=${promotedGame}&device=${device}&token=${token}&demo=0&lang=${params.lang}`;
      window.location.replace(url);
    } catch (error: any) {
      if (error.response.data.errorMessage) {
        setErrorMessage(error.response.data.errorMessage);
      } else {
        setErrorMessage(error.response.data.error);
      }
    }
  };

  return (
    <div className="register">
      {<Particles params={particlesConfig as any} />}
      <div className="register-form">
        <div className="register-form-head">
          <img src={logo} className="logo" alt="zitro digital" />
          <h1 className="register-title animate-title">
            ICE’24 DAILY TOURNAMENTS
          </h1>
        </div>
        <form onSubmit={handleSubmit}>
          <label>
            <FontAwesomeIcon icon={faUser as IconProp} />
            <input type="text" placeholder="Username *" />
          </label>
          <label>
            <FontAwesomeIcon icon={faEnvelope as IconProp} />
            <input type="email" placeholder="Email *" />
          </label>
          <label>
            <FontAwesomeIcon icon={faIdCard as IconProp} />
            <input type="text" placeholder="Real name" />
          </label>
          <label>
            <FontAwesomeIcon icon={faBuilding as IconProp} />
            <input type="text" placeholder="Company" />
          </label>
          <div className="register-btns">
            <button type="submit">REGISTER AND PLAY NOW</button>
          </div>
        </form>
        {errorMessage && (
          <div className="register-error">
            <p>{errorMessage}</p>
          </div>
        )}
        <div>
          <p className="register-disclaimer">ONLY FOR OPERATORS, PLATFORMS, AGGREGATORS AND DISTRIBUTORS REPRESENTATIVES</p>
        </div>
      </div>
    </div>
  );
}
