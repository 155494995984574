import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import { blue } from "@mui/material/colors";
import { Params } from "../../../interfaces/interfaces";

type Props = {
  params: Params;
  handleChange: <P extends keyof Params>(prop: P, value: Params[P]) => void;
};

export function PlatformRadio({ params, handleChange }: Props) {
  return (
    <div>
      <FormLabel className="label-padding">Platform</FormLabel>
      <Radio
        name="platform"
        value="slot-cocos"
        id="slot-cocos"
        checked={params.platform === "slot-cocos"}
        onChange={(e) => {
          handleChange("platform", e.target.value);
        }}
        sx={{
          color: blue[800],
          "&.Mui-checked": {
            color: blue[600],
          },
        }}
      />
      <span>slot-cocos</span>
      <Radio
        name="platform"
        value="slot-cocos-lgm"
        id="slot-cocos-lgm"
        checked={params.platform === "slot-cocos-lgm"}
        onChange={(e) => {
          handleChange("platform", e.target.value);
        }}
        sx={{
          color: blue[800],
          "&.Mui-checked": {
            color: blue[600],
          },
        }}
      />
      <span>slot-cocos-lgm</span>
      <Radio
        name="platform"
        value="slot-link"
        id="slot-link"
        checked={params.platform === "slot-link"}
        onChange={(e) => {
          handleChange("platform", e.target.value);
        }}
        sx={{
          color: blue[800],
          "&.Mui-checked": {
            color: blue[600],
          },
        }}
      />
      <span>slot-link</span>
      <Radio
        name="platform"
        value="slot-pw"
        id="slot-pw"
        checked={params.platform === "slot-pw"}
        onChange={(e) => {
          handleChange("platform", e.target.value);
        }}
        sx={{
          color: blue[800],
          "&.Mui-checked": {
            color: blue[600],
          },
        }}
      />
      <span>slot-pw</span>
      <Radio
        name="platform"
        value="vb"
        id="vb"
        checked={params.platform === "vb"}
        onChange={(e) => {
          handleChange("platform", e.target.value);
        }}
        sx={{
          color: blue[800],
          "&.Mui-checked": {
            color: blue[600],
          },
        }}
      />
      <span>vb</span>
    </div>
  );
}
