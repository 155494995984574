import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import { blue } from "@mui/material/colors";

type Props = {
  gameType: string;
  handleGameTypeChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    value: boolean
  ) => void;
};

export function GameTypeRadio({ gameType, handleGameTypeChange }: Props) {
  return (
    <div>
      <FormLabel className="label-padding">Game Type</FormLabel>
      <Radio
        name="gameType"
        value="slot"
        id="slot"
        checked={gameType === "slot"}
        onChange={handleGameTypeChange}
        sx={{
          color: blue[800],
          "&.Mui-checked": {
            color: blue[600],
          },
        }}
      />
      <span>slot</span>
      <Radio
        name="gameType"
        value="bingo"
        id="bingo"
        checked={gameType === "bingo"}
        onChange={handleGameTypeChange}
        sx={{
          color: blue[800],
          "&.Mui-checked": {
            color: blue[600],
          },
        }}
      />
      <span>bingo</span>
    </div>
  );
}
