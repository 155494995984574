import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import { blue } from "@mui/material/colors";

type Props = {
  setBranchTest: (isBranchTest: boolean) => void;
  isBranchTest: boolean;
};

export function TestByBranchCheckbox({
  isBranchTest,
  setBranchTest,
}: Props) {
  return (
    <>
      <div>
        <FormLabel>
          Test por rama/versión
          <Checkbox
            checked={isBranchTest}
            name="branch"
            onChange={(e) => {
              setBranchTest(e.target.checked);
            }}
            sx={{
              color: blue[800],
              "&.Mui-checked": {
                color: blue[600],
              },
            }}
          ></Checkbox>
        </FormLabel>
      </div>
    </>
  );
}
