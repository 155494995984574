import { useLauncherStore } from "../../store/launcher";
import { useConfigProvider } from "../../contexts/ConfigProvider";
import { Game, Params } from "../../interfaces/interfaces";
import React, { useEffect, useCallback } from "react";
import * as DefaultComponents from "./index";

type Props = {
  setUrlForBranch: (url: string) => void;
  urlForBranch: string;
  isBranchTest: boolean;
  setBranchTest: (isBranchTest: boolean) => void;
  params: Params;
  setParams: (params: Params) => void;
  slotGamesSorted: Game[];
  videoBingoGamesSorted: Game[];
};

export default function DefaultFields({
  urlForBranch,
  setUrlForBranch,
  isBranchTest,
  setBranchTest,
  params,
  setParams,
  slotGamesSorted,
  videoBingoGamesSorted,
}: Props) {
  const { features } = useConfigProvider();
  const { gameType, setGameType, config, setConfig, iFrameCompatibility } =
    useLauncherStore((state) => state);

  const handleChange = useCallback(<P extends keyof Params>(prop: P, value: Params[P]) => {
    setParams({ ...params, [prop]: value });
  },
  [setParams, params]
);

  const handleGameTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGameType(e.target.value);
  };

  const handleConfigChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfig({
      ...config,
      [e.target.name]: (e.target as HTMLInputElement).checked,
    });
  };

  const handleChangeGame = (value: string, name: string): void => {
    setParams({ ...params, [name]: value });
  };

  useEffect(() => {
    gameType === "bingo" && handleChange("platform", "vb");
    gameType === "slot" && handleChange("platform", "slot-cocos-lgm");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameType] );

  return (
    <>
      {features.withBranch && (
        <DefaultComponents.TestByBranchCheckbox
          isBranchTest={isBranchTest}
          setBranchTest={setBranchTest}
        />
      )}
      {isBranchTest && (
        <DefaultComponents.BranchUrlTextField
          urlForBranch={urlForBranch}
          setUrlForBranch={setUrlForBranch}
        />
      )}
      {isBranchTest && (
        <DefaultComponents.GameTypeRadio
          gameType={gameType}
          handleGameTypeChange={handleGameTypeChange}
        />
      )}
      {isBranchTest && (
        <DefaultComponents.PlatformRadio
          params={params}
          handleChange={handleChange}
        />
      )}
      {isBranchTest && (
        <DefaultComponents.BranchTextField
          params={params}
          handleChange={handleChange}
        />
      )}
      <DefaultComponents.GameSelect
        params={params}
        isBranchTest={isBranchTest}
        handleChangeGame={handleChangeGame}
        gameType={gameType}
        slotGamesSorted={slotGamesSorted}
        videoBingoGamesSorted={videoBingoGamesSorted}
      />

      <DefaultComponents.DemoCheckbox
        params={params}
        handleChange={handleChange}
      />

      {iFrameCompatibility && (
        <DefaultComponents.IframeModeCheckbox
          handleConfigChange={handleConfigChange}
          config={config}
        />
      )}
      {config.iFrameMode && (
        <DefaultComponents.ReloadOnChangeCheckbox
          handleConfigChange={handleConfigChange}
          config={config}
        />
      )}
      {!config.iFrameMode && iFrameCompatibility && (
        <DefaultComponents.DeviceRadio
          params={params}
          handleChange={handleChange}
        />
      )}
      <DefaultComponents.HideFullScrenHandRadio
        params={params}
        handleChange={handleChange}
      />

      <DefaultComponents.LanguageSelect
        params={params}
        handleChange={handleChange}
      />

      <DefaultComponents.CashierTextField
        params={params}
        handleChange={handleChange}
      />

      <DefaultComponents.HomeURLTextField
        params={params}
        handleChange={handleChange}
      />

      {isBranchTest && (
        <DefaultComponents.CertificationTextField
          params={params}
          handleChange={handleChange}
        />
      )}
      {isBranchTest && (
        <DefaultComponents.ServiceNameTextField
          params={params}
          handleChange={handleChange}
        />
      )}
      {isBranchTest && (
        <DefaultComponents.BaseUrlTextField
          params={params}
          handleChange={handleChange}
        />
      )}
      {isBranchTest && (
        <DefaultComponents.ProviderIdTextField
          params={params}
          handleChange={handleChange}
        />
      )}
      {isBranchTest && (
        <DefaultComponents.ProviderOperatorIdField
          params={params}
          handleChange={handleChange}
        />
      )}
    </>
  );
}
