import axios from "axios";
import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import {USERNAME_API_JACkPOTS,PASSWORD_API_JACkPOTS} from "../constants";
import { DataJackpots, Jackpots } from "../interfaces/interfaces";
import { useConfigProvider } from "../contexts/ConfigProvider";

async function fetchJackpots({ queryKey }:QueryFunctionContext) {
  const [, baseUrl, providerOperatorId, currency, gameName] = queryKey
    const url = `${baseUrl}/jackpots?providerOperatorId=${providerOperatorId}&currency=${currency}&gameName=${gameName}`;
    const res = await axios.get<DataJackpots>(
        `${url}`,
        {
          headers: {
                Authorization: `Basic ${Buffer.from(`${USERNAME_API_JACkPOTS}:${PASSWORD_API_JACkPOTS}`).toString('base64')}`,
                "Content-Type": "application/json"
            },
        }
    );
    return res.data.jackpots;
}

export const useJackpots = (params: Jackpots) => {

  const {features} = useConfigProvider();
  const isEnabled = features.withJackpots ?? false;

  const { baseUrl, providerOperatorId, currency, gameName } = params
  return useQuery({
    queryKey: ['jackpots', baseUrl, providerOperatorId, currency, gameName],
    queryFn: fetchJackpots,
    keepPreviousData: false,
    refetchInterval: 30000,
    enabled: isEnabled
  });
};



