import React from "react";
import "./Preview.scss";
import newBrand from "../../assets/new.png";
import comingSoonBrand from "../../assets/coming-soon.png";
import topBrand from "../../assets/top.png";
import QrModal from "../QrModal/QrModal";
import { useConfigProvider } from "../../contexts/ConfigProvider";
import { Game, Jackpot, configVersion } from "../../interfaces/interfaces";
import { useGameParamsStore } from "../../store/gameParams";
import { useGameLangStore } from "../../store/gameLanguage";

type Props = {
  game: Game;
  demo: boolean;
  token: string;
  gameUrl: string;
  jackpot: Jackpot | null;
  configUrl: string;
  configurationVersion: configVersion | undefined;
};

const Preview = ({ game, token, demo, gameUrl, jackpot, configUrl, configurationVersion }: Props) => {
  const { features } = useConfigProvider();
  const lang = useGameLangStore((state) => state.lang);
  const { params } = useGameParamsStore((state) => state);
  const [previewLoaded, setPreviewLoaded] = React.useState<boolean>(false);
  const openGame = (e: React.MouseEvent<HTMLElement>) => {
    const device = params.isMobile ? "mobile" : "desktop";
    e.preventDefault();
    const url = `${gameUrl}?game=${
      game.name
    }&device=${device}&token=${token}&demo=${+demo}&lang=${lang}`;
    window.open(url);
  };

  const loadPreviewImage = () => {
    const img = new Image();
    img.src = game.isAnimated
    ? `${configUrl}/configurations/images/showroom/previews/preview-${game.name}.svg?version=${configurationVersion?.version}`
    : `${configUrl}/configurations/images/showroom/previews/preview-${game.name}.jpg?version=${configurationVersion?.version}`;
    
    img.onload = () => {
      setPreviewLoaded(true);
    };
    img.onerror = () => {
      setPreviewLoaded(false);
    };
  };

  loadPreviewImage();

  return (
    <div className="preview-container">
      <div className="preview">
        {game.isNew && (
          <img
            src={newBrand}
            className="preview-isTagged"
            alt="zitro digital"
          />
        )}
        {game.isComingSoon && (
          <img
            src={comingSoonBrand}
            className="preview-isTagged"
            alt="zitro digital"
          />
        )}
        {game.isTop && (
          <img
            src={topBrand}
            className="preview-isTagged"
            alt="zitro digital"
          />
        )}
        {previewLoaded ? (
          <img
            src={
              game.isAnimated
                ? `${configUrl}/configurations/images/showroom/previews/preview-${game.name}.svg?version=${configurationVersion?.version}`
                : `${configUrl}/configurations/images/showroom/previews/preview-${game.name}.jpg?version=${configurationVersion?.version}`
            }
            className="preview-image"
            alt={game.name}
          />
        ) : (
          <div className="preview-default-image">
            <p>{game.description}</p>
          </div>
        )}

        <div className="preview-selector">
          <div className="title">{game.description}</div>
          <button onClick={(game.isPlayable === undefined || game.isPlayable) ? openGame : undefined}>
            <span className="material-symbols-outlined">
              {(game.isPlayable === undefined || game.isPlayable) ? "play_arrow" : "block"}
            </span>          
          </button>
        </div>
        {(features.withQRCode && (game.isPlayable === undefined || game.isPlayable)) && (
          <QrModal game={game} token={token} gameUrl={gameUrl} />
        )}
      </div>
      {features.withJackpots &&
        (jackpot ? (
          <div className="preview-jackpot">{`€ ${Intl.NumberFormat().format(
            jackpot?.value / 100
          )}`}</div>
        ) : (
          <div></div>
        ))}
    </div>
  );
};

export default React.memo(Preview);
