import React from "react";
import { EQUIVALENT_KEYS } from "../../../constants";
import FormLabel from "@mui/material/FormLabel";
import { StringIndex } from "../../../interfaces/interfaces";
import Radio from "@mui/material/Radio";
import { blue } from "@mui/material/colors";
import TextField from "@mui/material/TextField";

type Props = {
  entrie: string[];
  providerInputData: StringIndex;
  handleInputsChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export function DeviceRadio({
  entrie,
  providerInputData,
  handleInputsChange,
}: Props) {
  return (
    <React.Fragment>
      {EQUIVALENT_KEYS.device.includes(entrie[1]) ? (
        <div>
          <FormLabel>{entrie[0]}</FormLabel>
          <Radio
            name={entrie[1]}
            value="desktop"
            id="desktop"
            checked={providerInputData?.[`${entrie[1]}`] === "desktop"}
            onChange={handleInputsChange}
            sx={{
              color: blue[800],
              "&.Mui-checked": {
                color: blue[600],
              },
            }}
          />
          <span>desktop</span>
          <Radio
            name={entrie[1]}
            value="mobile"
            id="mobile"
            checked={providerInputData?.[`${entrie[1]}`] === "mobile"}
            onChange={handleInputsChange}
            sx={{
              color: blue[800],
              "&.Mui-checked": {
                color: blue[600],
              },
            }}
          />
          <span>mobile</span>
        </div>
      ) : (
        <React.Fragment>
          <FormLabel>{entrie[0]}</FormLabel>
          <TextField
            placeholder={`${entrie[0]}`}
            name={entrie[1]}
            onChange={handleInputsChange}
            value={
              providerInputData?.[`${entrie[1]}`] === undefined
                ? ""
                : providerInputData?.[`${entrie[1]}`]
            }
            sx={{
              "& fieldset": {
                borderColor: blue[800],
              },
              "&:hover": {
                "&& fieldset": {
                  border: "1px solid white",
                },
              },
            }}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
